import {Routes} from '@angular/router';
import {authGuard} from "@core/auth/guard/auth.guard";


export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@features/features.routes').then(m => m.featuresRoutes)
  },
  {
    path: 'orders',
    loadComponent: () => import('@features/orders/orders.component').then((m) => m.OrdersComponent),
    canActivate: [authGuard],
  },
  {
    path: 'dispatcher',
    loadComponent: () => import('@features/dispatcher/dispatcher.component').then((m) => m.DispatcherComponent),
    canActivate: [authGuard],
  },
  {
    path: 'tv',
    loadComponent: () => import('@features/tv/tv.component').then((m) => m.TvComponent),
  },
  {
    path: 'login',
    loadComponent: () => import('@core/auth/pages/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: 'reset-password',
    loadComponent: () => import('@core/auth/pages/reset-password/reset-password.component').then((m) => m.ResetPasswordComponent),
  },
  {
    path: 'no-access',
    loadComponent: () => import('@core/auth/pages/no-access/no-access.component').then((m) => m.NoAccessComponent)
  },
  {
    path: '**',
    loadComponent: () => import('@core/not-found/not-found.component').then((m) => m.NotFoundComponent)
  }
];
